import axios from "axios";
import React, { useState } from "react";
import "./App.css";

const App = () => {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [extra, setExtra] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
  setLoading(true);
  event.preventDefault();
  const extraInfo = extra.length !== 0 ? ` Extra info: ${extra}` : "";
  const prompt = `Give me a long great and profesional, human like description with this context: App Name:${name} What the app does:${category} App price:${price}${extraInfo}`;

  try {
    // after deployment you should change the fetch URL below
    const response = await fetch("https://descriptionai.servelogy.com/api/generateDescription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message: prompt,
      }),
    });

    const data = await response.json();
    setDescription(data.message);
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};

  return (
    <div className="wrapper">
      {loading ? (
        <div className="content_box">
          <h1>DescriptionAI</h1>
          <h4>Enter your app details to generate a description for your app</h4>
          <form className="relative">
            {/* loader */}
            <div className="loader" />
            <div>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                required
                placeholder="Enter your app name..."
              />{" "}
              <input
                type="text"
                placeholder="What the app does..."
                value={category}
                required
                onChange={(e) => setCategory(e.target.value)}
              />{" "}
              <input
                type="text"
                placeholder="Price with currency or free..."
                required
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />{" "}
              <input
                type="text"
                placeholder="Add anything extra like features etc.."
                value={extra}
                onChange={(e) => setExtra(e.target.value)}
              />{" "}
              <input
                type="submit"
                value={
                  description.length > 0 ? "Regenerating..." : "Generating..."
                }
                className="submit_btn"
                style={{ cursor: "no-drop" }}
              />
              {description.length > 0 ? (
                <p>
                  {" "}
                  <strong>Description: </strong>
                  {description}
                </p>
              ) : null}
            </div>
          </form>
        </div>
      ) : (
        <div className="content_box">
          <h1>DescriptionAI</h1>
          <h4>Enter your app details to generate a description for your app</h4>
          <form onSubmit={handleSubmit} className="relative">
            <div>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                required
                placeholder="Enter your app name..."
              />{" "}
              <input
                type="text"
                placeholder="What the app does..."
                value={category}
                required
                onChange={(e) => setCategory(e.target.value)}
              />{" "}
              <input
                type="text"
                placeholder="Price with currency or free..."
                required
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />{" "}
              <input
                type="text"
                placeholder="Add anything extra like features etc.."
                value={extra}
                onChange={(e) => setExtra(e.target.value)}
              />{" "}
              <input
                type="submit"
                value={description.length > 0 ? "Regenerate" : "Generate"}
                className="submit_btn"
              />
              {description.length > 0 ? (
                <p>
                  {" "}
                  <strong>Description: </strong>
                  {description}
                </p>
              ) : null}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default App;
